<template>
	<div class="tabbar">
		<template v-for="(item, index) in tabbarList">
			<div class="flex-center font-light pointer" @click="tabbarClick(item.type)" :key="index"
				v-if="item.id !== typeId && typeIdOne !== item.id" :class="[item.id !== '' ? 'typeClass' : '',typeIndex == index ? 'active-class' : '',]">
				{{ item.text }}
			</div>
		</template>
	</div>
</template>

<script>
	import {
		mapState
	} from "vuex";
	import api from "../api/user";
	export default {
		components: {},
		computed: {
			// 2. 解构状态作为计算属性
			...mapState(["userDetail"]),
		},
		props: {
			typeId: {
				type: Number,
			},
			typeIndex: {
				type: Number,
			},
			typeIdOne: {
				type: Number,
			},
		},

		data() {
			return {
				tabbarList: [{
						text: "个人中心",
						id: "",
						type: "Personal-center",
					},
					{
						text: "我的简历",
						id: "",
						type: "My-Detail",
					},
					{
						text: "基本信息",
						id: 1,
						type: "My-Basic-Information",
					},
					{
						text: "个人优势",
						id: 1,
						type: "My-Advantage",
					},
					{
						text: "求职意向",
						id: 1,
						type: "My-Objective",
					},
					{
						text: "工作经历",
						id: 1,
						type: "My-Experience",
					},
					{
						text: "教育经历",
						id: 1,
						type: "My-Educational",
					},
					{
						text: "资格证书",
						id: 1,
						type: "My-Certificate",
					},
					{
						text: "求职反馈",
						id: "",
						type: "JobHunting",
					},
					{
						text: "在线互动",
						id: "",
						type: "Interaction",
					},
					{
						text: "账号设置",
						id: "",
						type: "Privacy",
					},
					{
						text: "个人隐私",
						id: 2,
						type: "Privacy",
					},
					{
						text: "个人账号与绑定",
						id: 2,
						type: "UserAccount",
					},
				],
			};
		},

		created() {},

		mounted() {},

		methods: {
			// 跳转路由
			tabbarClick(type) {
				this.getUserInfo()
				if (
					this.userDetail.user_type == 2 &&
					(type == "Interaction" || type == "JobHunting")
				) {
					this.$emit("toggleClick");
					return;
				}
				this.$router.push({
					name: type
				});
			},
			// 获取用户信息
			getUserInfo() {
				api.userDetail().then((res) => {
					if (res.code == 200) {
						this.$store.commit("SET_USER", res.data);
					}
				});
			},
		},
	};
</script>
<style lang="less" scoped>
	.tabbar {
		width: 10.9375rem;
		background: #fff;
		color: #24272e;

		div {
			width: 100%;
			height: 3.125rem;
		}

		.typeClass {
			color: #61687c;
			font-size: 0.875rem;
		}

		.active-class {
			background: #126bf9;
			color: #fff;
		}
	}
</style>

<template>
	<div class="account">
		<logged-heard></logged-heard>
		<div class="account-center space-between">
			<tabbar-model :typeIndex="12" :typeId="1" @toggleClick="toggleClick"></tabbar-model>
			<div class="one center-right" v-if="userDetail">
				<div class="right-padding">
					<!-- 手机号 -->
					<div class="padding-item font-normal">
						<div class="space-between">
							<div class="row-center">
								<span class="text-main">{{
                  userDetail.phone ? "当前绑定手机号" : "当前未绑定手机号"
                }}</span>
								<div class="phone" v-if="userDetail.phone">{{ userDetail.phone }}</div>
							</div>
							<div class="row-center pointer" v-if="userDetail.phone" @click="phoneEdit">
								<img src="../../assets/glxt/18.png" class="img" alt="" />
								<span class="text-blue">解除绑定</span>
							</div>
							<div class="row-center pointer" v-else @click="phoneAdd">
								<img src="../../assets/glxt/10.png" alt="" />
								<span class="text-blue">添加</span>
							</div>
						</div>
						<div>注：修改后可使用新手机登录干纺织平台，企业获得的手机号将同步修改</div>
					</div>
					<!-- 已绑定微信 -->
					<div class="padding-item padding-wx-item font-normal" v-if="userDetail.is_bindwx == 1">
						<div class="space-between">
							<div class="row-center">
								<span class="text-main">绑定微信</span>
							</div>
							<div class="row-center pointer position-relative" @click="wxEditShowClick">
								<img src="../../assets/glxt/18.png" alt="" />
								<div class="text-blue position-relative">
									<span>解除绑定</span>
									<div class="position-absolute edit-wx" v-if="wxEditShow">
										<wx-edit @WxeditSubmit="WxeditSubmit" :centerText="wxCurrentText"
											:title="wxTitle"></wx-edit>
									</div>
								</div>
							</div>
						</div>
						<div class="wx-text">注：绑定微信后，可使用微信扫一扫功能登录</div>
						<div class="row-center wx-logo">
							<img :src="userDetail.avatar_url" alt="" />
							<div>{{ userDetail.user_name }}</div>
						</div>
					</div>
					<!-- 未绑定微信微信 -->
					<div class="padding-item font-normal" v-else>
						<div class="space-between">
							<div class="row-center">
								<span class="text-main">当前未绑定微信</span>
								<!-- <div class="phone">158****1234</div> -->
							</div>
							<div class="row-center pointer" @click="wxAddClick">
								<img src="../../assets/glxt/10.png" alt="" />
								<div class="text-blue position-relative">
									<span>添加</span>
									<div class="position-absolute add-wx" v-if="wxAddShow">
										<wx-add :showModel="wxAddShow"></wx-add>
									</div>
								</div>
							</div>
						</div>
						<div>注：绑定微信后，可使用微信扫一扫功能登录</div>
					</div>
					<!-- 已绑定邮箱 -->
					<div class="padding-item padding-wx-item font-normal" v-if="userDetail.email">
						<div class="space-between">
							<div class="row-center">
								<span class="text-main">绑定邮箱</span>
								<!-- <div class="phone">158****1234</div> -->
							</div>
							<div class="row-center pointer position-relative" @click="EmailEditShow = true">
								<img src="../../assets/glxt/18.png" alt="" />
								<div class="text-blue position-relative">
									<span>解除绑定</span>
									<div class="position-absolute edit-wx" v-if="EmailEditShow">
										<wx-edit @WxeditSubmit="EmaileditSubmit" :centerText="emailCurrentText"
											:title="emailTitle"></wx-edit>
									</div>
								</div>
							</div>
						</div>
						<div class="wx-text">注：绑定邮箱后，忘记密码时可通过邮箱找回密码</div>
						<div class="email-text">{{ userDetail.email }}</div>
					</div>
					<!-- 未绑定邮箱 -->
					<div class="padding-item font-normal" v-else>
						<div class="space-between">
							<div class="row-center">
								<span class="text-main">当前未绑定邮箱</span>
								<!-- <div class="phone">158****1234</div> -->
							</div>
							<div class="row-center pointer" @click="emailAddShow = true">
								<img src="../../assets/glxt/10.png" alt="" />
								<span class="text-blue">添加</span>
							</div>
						</div>
						<div>注：绑定邮箱后，忘记密码时可通过邮箱找回密码</div>
					</div>
				</div>
				<!-- 未绑定邮箱进行绑定 emailAddShow-->
				<div class="el-noet-email" v-if="emailAddShow">
					<div>绑定邮箱</div>
					<div class="el-email-input">
						<el-input placeholder="请输入您要绑定的邮箱" v-model="emailQuery.email"></el-input>
					</div>
					<div class="el-div-input space-between">
						<input type="text" placeholder="验证码" v-model="emailQuery.code" />
						<div class="text-blue pointer font-normal el-code-num" @click="emailCode(1, 1)">
							{{ second }}
						</div>
					</div>
					<div class="email-btn row-end text-main font-normal">
						<div class="flex-center pointer" @click="emailAddShow = false">取消</div>
						<div class="bg-blue text-white flex-center pointer" @click="emailSubmitClick(1)">
							确认
						</div>
					</div>
				</div>
				<!-- 设置登录密码 -->
				<div class="right-padding-one">
					<div class="row-center font-normal text-main">
						<span>当前登录账号</span>
						<div class="password-phone">
							{{ userDetail.phone ? userDetail.phone : "暂未绑定手机号码" }}
						</div>
					</div>
					<!-- 已设置登录密码 -->
					<div class="row-center font-normal text-main current-password" v-if="userDetail.password">
						<span>当前密码</span>
						<div class="password-phone password-phone-one">*********</div>
					</div>
					<div v-if="userDetail.phone">
						<div class="row-center text-main font-normal el-text-input">
							<span class="el-font-size">设置登录密码</span>
							<div class="el-text-div-input">
								<input type="password" placeholder="请输入密码" v-model="passwordQuery.password" />
							</div>
						</div>
						<div class="el-confirm-input">
							<div class="el-confirm-firrst-div">
								<input type="password" placeholder="请确认密码" v-model="passwordQuery.repwd" />
							</div>
							<div class="el-div-input space-between">
								<input type="text" placeholder="验证码" v-model="passwordQuery.code" />
								<div class="text-blue pointer el-code-input font-normal" @click="codeClick(3)">
									{{ secondThree }}
								</div>
							</div>
							<div class="bg-blue text-white flex-center font-normal pointer" @click="passwordSubmit">
								设置密码
							</div>
						</div>
					</div>
					<div class="row-center font-normal text-main text-zfb">
						<span>个人认证</span>

						<div v-if="userDetail.is_auth == 1">已完成个人认证</div>
						<div v-if="userDetail.is_auth == 0 && certificationShow">请输入个人信息</div>
						<div v-if="userDetail.is_auth == 0 && !certificationShow">
							请使用支付宝扫码完成实名认证
						</div>
					</div>
					<div class="el-certification-input" v-if="userDetail.is_auth == 0 && certificationShow">
						<div class="el-certification-div">
							<input type="text" placeholder="请输入真实姓名" v-model="certificationQuery.name" />
						</div>
						<div class="el-certification-div">
							<input type="text" placeholder="请输入身份证号" v-model="certificationQuery.card_no" />
						</div>
						<div class="bg-blue text-white flex-center font-normal pointer el-button-submit"
							@click="certificationClick">
							确定
						</div>
					</div>
					<img :src="qrcode_url" class="el-zfb-img" alt=""
						v-if="userDetail.is_auth == 0 && !certificationShow" />
				</div>
			</div>
		</div>
		<!-- 修改和添加手机号码 -->
		<mask-model sureContent="保存" ref="phoneModel" :title="titlePhone" width="480px" :btnCancel="false"
			:btnSubShow="false" @hide="Addhide">
			<div class="edit-phone">
				<el-form :model="ruleForm" :rules="rules" ref="ruleForm">
					<el-form-item :label="labelPhone" prop="phone">
						<el-input v-model="ruleForm.phone" :maxlength="11" :clearable="true" :disabled="disabledPhone"
							placeholder="请输入手机号码"></el-input>
					</el-form-item>
					<el-form-item label="验证码" prop="code">
						<div class="el_code-div space-between">
							<el-input v-model="ruleForm.code" placeholder="请输入验证码"></el-input>
							<el-button type="primary" :disabled="disabled" @click="codeClick(2)"
								style="margin-left: 10px">{{ secondTwo }}</el-button>
						</div>
					</el-form-item>
					<el-form-item style="display: flex; justify-content: flex-end; margin-bottom: 0">
						<el-button @click="AddhideOne">取消</el-button>
						<el-button type="primary" @click="submitClick('ruleForm')">保存</el-button>
					</el-form-item>
				</el-form>
			</div>
		</mask-model>
		<!-- 解绑微信号验证手机号码 -->
		<mask-model title="请验证手机号码" width="530px" ref="wxPhone" :interview="false" @submitClick="wxPhoneSubmit">
			<div class="wx-phone">
				<div class="row-center wx-phone-text text-main font-normal">
					<div>当前登录账号</div>
					<div>{{ userDetail.phone }}</div>
				</div>
				<div class="space-between wx-phone-input font-normal">
					<div>短信验证码</div>
					<div class="div-input space-between one">
						<input type="text" placeholder="验证码" v-model="code" />
						<div class="text-blue pointer font-normal" @click="codeClickOne">
							{{ secondOne }}
						</div>
					</div>
				</div>
			</div>
		</mask-model>
		<!-- 解绑邮箱验证邮箱 -->
		<mask-model title="请验证邮箱" width="530px" ref="emailPhone" :interview="false" @hide="EmailPhoneHide"
			@submitClick="emailSubmitClick(2)">
			<div class="wx-phone">
				<div class="row-center wx-phone-text text-main font-normal">
					<div>当前绑定邮箱</div>
					<div>{{ userDetail.email }}</div>
				</div>
				<div class="space-between wx-phone-input font-normal">
					<div class="email-phone">验证码</div>
					<div class="div-input space-between one">
						<input type="text" placeholder="邮箱验证码" v-model="emailQuery.code" />
						<div class="text-blue pointer font-normal" @click="emailCode(2, 4)">
							{{ secondFour }}
						</div>
					</div>
				</div>
			</div>
		</mask-model>
		<feet-model></feet-model>
	</div>
</template>

<script>
	import loggedHeard from "../../components/loggedHeard.vue";
	import feetModel from "../../components/feet.vue";
	import tabbarModel from "../../components/tabbar.vue";
	import wxEdit from "./wxEdit.vue";
	import wxAdd from "./wxAdd.vue";
	import maskModel from "../../components/mask.vue";
	import {
		mapState
	} from "vuex";
	import api from "../../api/user";
	export default {
		components: {
			loggedHeard,
			feetModel,
			tabbarModel,
			wxEdit,
			wxAdd,
			maskModel
		},
		data() {
			// 自定义验证手机号码以及短信验证码
			var validatePhone = (rule, value, callback) => {
				if (value === "") {
					callback(new Error("请输入手机号码"));
				} else if (!this.$util.checkStr(value, "mobile")) {
					callback(new Error("请输入正确的手机号码!"));
				} else {
					callback();
				}
			};
			var validateCode = (rule, value, callback) => {
				if (value === "") {
					callback(new Error("请输入短信验证码"));
				} else if (!this.$util.checkStr(value, "payPwd")) {
					callback(new Error("请输入正确的短信验证码!"));
				} else if (this.tisp === "获取验证码") {
					callback(new Error("请先获取短信验证码!"));
				} else {
					callback();
				}
			};
			return {
				textWx: true,
				wxEditShow: false,
				// 是否完成个人认证
				certificationShow: true,
				certificationQuery: {
					name: "",
					card_no: "",
				},
				wxAddShow: false,
				emailAddShow: false,
				EmailEditShow: false,
				wxCurrentText: "解除绑定后，将不能在使用微信登录干纺织，确认解绑吗？",
				wxTitle: "解除微信绑定",
				emailCurrentText: "解除绑定后，将不能在使用邮箱找回密码，确认解绑吗？",
				emailTitle: "解除邮箱绑定",
				// 是否绑定手机号码
				phoneShow: true,
				titlePhone: "添加手机号",
				ruleForm: {
					phone: "",
					code: "",
				},
				// 验证规则
				rules: {
					phone: [{
							required: true,
							message: "请输入手机号码",
							trigger: "blur"
						},
						{
							validator: validatePhone,
							trigger: "blur",
						},
					],
					code: [{
							required: true,
							message: "请输入6位验证码",
							trigger: "blur"
						},
						{
							validator: validateCode,
							trigger: "blur",
						},
					],
				},
				labelPhone: "新手机号",
				second: "发送验证码",
				showText: true,
				showTextOne: true,
				showTextTwo: true,
				showTextThree: true,
				showTextFour: true,
				phoneType: 2,
				emailQuery: {
					email: "",
					code: "",
				},
				passwordQuery: {
					password: "",
					repwd: "",
					code: "",
				},
				setInterTime: null,
				code: "",
				showText: true,
				secondOne: "发送验证码",
				qrcode_url: "",
				setTime: null,
				secondTwo: "发送验证码",
				secondThree: "发送验证码",
				secondFour: "发送验证码",
				disabledPhone: false,
				setTimeTwo: null,
			};
		},

		created() {},
		mounted() {},
		beforeDestroy() {
			clearInterval(this.setInterTime);
			clearInterval(this.setTime);
		},
		computed: {
			disabled() {
				return (
					this.ruleForm.phone === "" || !this.$util.checkStr(this.ruleForm.phone, "mobile")
				);
			},
			// 2. 解构状态作为计算属性
			...mapState(["userDetail"]),
		},
		methods: {
			toggleClick() {
				this.$emit('toggleClick');
			},
			// 单独查询支付宝认证
			queryCertify() {
				this.setTime = setInterval(() => {
					api.queryCertify().then((res) => {
						if (res.code == 200) {
							if (res.data.status == 1) {
								clearInterval(this.setTime);
								this.$util.msg(res.msg);
								this.getUserInfo();
							}
						}
					});
				}, 1000);
			},
			getUserCertify() {
				api.getUserCertify(this.certificationQuery).then((res) => {
					if (res.code == 200) {
						this.qrcode_url = res.data.qrcode_url;
						this.certificationShow = false;
						this.queryCertify();
					}
				});
			},
			certificationClick() {
				if (this.certificationQuery.name.trim() == "") {
					this.$util.msg("请输入姓名", "error");
					return;
				} else if (
					this.certificationQuery.name.trim().length > 5 ||
					this.certificationQuery.name.trim().length < 2
				) {
					this.$util.msg("范围在2~5个字符之间", "error");
					return;
				} else if (!this.$util.checkStr(this.certificationQuery.card_no, "card")) {
					this.$util.msg("请输入正确的身份证号", "error");
					return;
				}
				this.getUserCertify();
			},
			// 解绑微信
			unBindWechat() {
				api.unBindWechat({
					code: this.code
				}).then((res) => {
					if (res.code == 200) {
						this.$refs.wxPhone.hide();
						this.$util.msg(res.msg);
						this.getUserInfo();
					}
				});
			},
			// 发送短信验证码
			codeClickOne() {
				const loading = this.$loading({
					lock: true,
					text: "正在获取验证码",
					spinner: "el-icon-loading",
					background: "rgba(0, 0, 0, 0.7)",
				});
				if (this.showTextOne == false) {
					this.$util.msg("请不要重复点击", "warning");
					loading.close();
					return false;
				} else {
					setTimeout(() => {
						loading.close();
						this.codeCountdownOne();
						this.sendSmsOne();
					}, 2000);
				}
			},
			// 获取短信验证码
			sendSmsOne() {
				api.getSms({
					phone: this.userDetail.phone,
					type: 5
				}).then((res) => {
					if (res.code == 200) {
						this.$util.msg(res.msg, "success");
					}
				});
			},
			// 倒计时
			// 验证码倒计时
			codeCountdownOne() {
				this.showTextOne = false;
				let time = 60;
				if (time > 0) {
					var a = setInterval(() => {
						time = time - 1;
						this.secondOne = "重新发送" + time + "s";
						if (time == 0) {
							clearInterval(a);
							this.showTextOne = true;
							this.secondOne = "发送验证码";
						}
					}, 1000);
				}
			},
			wxEditShowClick() {
				if (this.userDetail.phone) {
					this.wxEditShow = true;
				} else {
					this.$util.msg("请先绑定手机号码", "error");
				}
			},
			// 接触绑定微信相关事件
			WxeditSubmit(type) {
				// type为1为取消，type为2为接触绑定
				this.wxEditShow = false;
				if (type == 2) {
					this.$refs.wxPhone.show();
				}
			},
			// 解绑微信确定事件
			wxPhoneSubmit() {
				if (this.code.trim() == "") {
					this.$util.msg("请输入短信验证码", "warning");
					return;
				}
				this.unBindWechat();
			},
			// 解除邮箱绑定事件
			EmaileditSubmit(type) {
				this.EmailEditShow = false;
				if (type == 2) {
					this.$refs.emailPhone.show();
				}
			},
			// 解绑邮箱弹窗取消事件
			EmailPhoneHide() {
				this.emailQuery.code = "";
				this.emailQuery.email = "";
			},
			// 添加微信绑定
			wxAddClick() {
				this.wxAddShow = !this.wxAddShow;
				if (this.wxAddShow) {
					this.pollingClick();
				}
			},
			// 轮询获取绑定微信返回的code
			pollingClick() {
				this.setInterTime = setInterval(() => {
					// console.log(this.$route.query, "参数code");
					// 获取微信登录传递过来的code
					let query = this.$route.query;
					if (query.code) {
						clearInterval(this.setInterTime);
						let code = query.code;
						this.bindWechat(code);
					}
				}, 1000);
			},
			// 绑定微信
			bindWechat(code) {
				api.bindWechat({
					code
				}).then((res) => {
					if (res.code == 200) {
						this.wxAddShow = false;
						this.$util.msg(res.msg);
						this.getUserInfo();
					}
				});
			},
			// 添加手机号码
			phoneAdd() {
				this.titlePhone = "添加手机号";
				this.$refs.phoneModel.show();
				this.labelPhone = "新手机号";
				this.disabledPhone = false;
				this.phoneType = 0;
			},
			// 修改手机号码
			phoneEdit() {
				this.titlePhone = "解绑手机号";
				this.$refs.phoneModel.show();
				this.labelPhone = "旧手机号";
				this.disabledPhone = true;
				this.ruleForm.phone = this.userDetail.phone;
				this.phoneType = 1;
			},
			Addhide() {
				this.$util.objClear(this.ruleForm);
			},
			AddhideOne() {
				this.$refs.phoneModel.hide();
				this.$util.objClear(this.ruleForm);
			},
			// 获取用户信息
			getUserInfo() {
				api.userDetail().then((res) => {
					if (res.code == 200) {
						this.AddhideOne();
						this.$refs.emailPhone.hide();
						this.$store.commit("SET_USER", res.data);
					}
				});
			},
			//解绑和绑定手机号码
			submitClick() {
				this.$refs.ruleForm.validate((valid) => {
					if (valid) {
						if (this.phoneType == 1) {
							api.unBindPhone(this.ruleForm).then((res) => {
								if (res.code == 200) {
									this.ruleForm.phone = "";
									this.ruleForm.code = "";
									this.$util.msg(res.msg);
									this.getUserInfo();
								}
							});
						} else {
							api.bindPhone(this.ruleForm).then((res) => {
								if (res.code == 200) {
									this.ruleForm.phone = "";
									this.ruleForm.code = "";
									this.$util.msg(res.msg);
									this.getUserInfo();
								}
							});
						}
						setTimeout(() => {
							clearInterval(this.setTimeTwo);
							this.secondTwo = "发送验证码";
							this.showTextTwo = true;
						}, 300);
					}
				});
			},
			yzgz() {
				if (this.userDetail.phone == "") {
					this.$util.msg("请先绑定手机号码", "error");
					return false;
				} else if (this.passwordQuery.password == "") {
					this.$util.msg("请先输入密码", "error");
					return false;
				} else if (
					this.passwordQuery.password.trim().length < 6 ||
					this.passwordQuery.password.trim().length > 20
				) {
					this.$util.msg("密码长度在6~20位之间", "error");
					return false;
				} else if (this.passwordQuery.password.trim() !== this.passwordQuery.repwd.trim()) {
					this.$util.msg("前后密码要一致", "error");
					return false;
				} else {
					return true;
				}
			},
			// 设置登录密码
			passwordSubmit() {
				if (!this.yzgz()) return;
				if (!this.$util.checkStr(this.passwordQuery.code, "mobileCode")) {
					this.$util.msg("请输入六位数验证码", "error");
					return;
				}
				api.setPassword(this.passwordQuery).then((res) => {
					if (res.code == 200) {
						this.$util.msg(res.msg);
						this.$util.objClear(this.passwordQuery);
						this.getUserInfo();
					}
				});
			},
			// 发送短信验证码
			codeClick(type) {
				if (type == 3) {
					if (!this.yzgz()) return;
				}
				const loading = this.$loading({
					lock: true,
					text: "正在获取验证码",
					spinner: "el-icon-loading",
					background: "rgba(0, 0, 0, 0.7)",
				});
				if (type == 2 && !this.showTextTwo) {
					this.$util.msg("请不要重复点击", "warning");
					loading.close();
					return;
				}
				if (type == 3 && !this.showTextThree) {
					this.$util.msg("请不要重复点击", "warning");
					loading.close();
					return;
				}
				setTimeout(() => {
					loading.close();
					if (type == 2) {
						this.codeCountdownTwo(type);
					} else {
						this.codeCountdownThree(type);
					}
					this.sendSms(type);
				}, 2000);
			},
			// 获取短信验证码
			sendSms(type) {
				let phone;
				if (type == 2) {
					phone = this.ruleForm.phone;
				} else {
					phone = this.userDetail.phone;
				}
				api.getSms({
					phone,
					type
				}).then((res) => {
					if (res.code == 200) {
						this.$util.msg(res.msg, "success");
					}
				});
			},
			// 发送邮箱验证码
			emailCode(type, typeOne) {
				if (type == 2) {
					this.emailQuery.email = this.userDetail.email;
				}
				if (!this.$util.checkStr(this.emailQuery.email, "email")) {
					this.$util.msg("请输入正确的邮箱号", "error");
					return;
				}
				const loading = this.$loading({
					lock: true,
					text: "正在获取验证码",
					spinner: "el-icon-loading",
					background: "rgba(0, 0, 0, 0.7)",
				});
				if (typeOne == 1 && !this.showText) {
					this.$util.msg("请不要重复点击", "warning");
					loading.close();
					return;
				}
				if (typeOne == 4 && !this.showTextFour) {
					this.$util.msg("请不要重复点击", "warning");
					loading.close();
					return;
				}
				setTimeout(() => {
					loading.close();
					this.sendEmail(type);
					if (typeOne == 1) {
						this.codeCountdown();
					} else {
						this.codeCountdownFour();
					}
				}, 2000);
			},
			sendEmail(type) {
				api.emailCode({
					email: this.emailQuery.email,
					type
				}).then((res) => {
					if (res.code == 200) {
						this.$util.msg(res.msg);
					}
				});
			},
			// 绑定邮箱
			emailSubmitClick(type) {
				if (type == 2) {
					this.emailQuery.email = this.userDetail.email;
				}
				if (!this.$util.checkStr(this.emailQuery.email, "email")) {
					this.$util.msg("请输入正确的邮箱号", "error");
					return;
				} else if (!this.$util.checkStr(this.emailQuery.code, "mobileCode")) {
					this.$util.msg("请输入六位数邮箱验证码", "error");
					return;
				}
				if (type == 1) {
					api.bindEmail(this.emailQuery).then((res) => {
						// console.log(res);
						if (res.code == 200) {
							this.$util.msg(res.msg);
							this.getUserInfo();
							this.$util.objClear(this.emailQuery);
							this.emailAddShow = false;
						}
					});
				} else {
					api.unBindEmail(this.emailQuery).then((res) => {
						if (res.code == 200) {
							this.$util.msg(res.msg);
							this.getUserInfo();
							this.$util.objClear(this.emailQuery);
							this.emailAddShow = false;
						}
					});
				}
			},
			// 倒计时
			// 验证码倒计时
			codeCountdown(type) {
				this.showText = false;
				let time = 60;
				if (time > 0) {
					var a = setInterval(() => {
						time = time - 1;
						if (type == 1) {
							this.second = "重新发送" + time + "s";
						} else if (type == 2) {
							this.secondTwo = "重新发送" + time + "s";
						} else if (type == 3) {
							this.secondThree = "重新发送" + time + "s";
						} else {
							this.secondFour = "重新发送" + time + "s";
						}
						if (time == 0) {
							clearInterval(a);
							this.showText = true;
							if (type == 1) {
								this.second = "发送验证码";
							} else if (type == 2) {
								this.secondTwo = "发送验证码";
							} else if (type == 3) {
								this.secondThree = "发送验证码";
							} else {
								this.secondFour = "发送验证码";
							}
						}
					}, 1000);
				}
			},
			codeCountdownFour(type) {
				this.showTextFour = false;
				let time = 60;
				if (time > 0) {
					var a = setInterval(() => {
						time = time - 1;
						this.secondFour = "重新发送" + time + "s";
						if (time == 0) {
							clearInterval(a);
							this.showTextFour = true;
							this.secondFour = "发送验证码";
						}
					}, 1000);
				}
			},
			codeCountdownTwo(type) {
				this.showTextTwo = false;
				let time = 60;
				if (time > 0) {
					this.setTimeTwo = setInterval(() => {
						time = time - 1;
						this.secondTwo = "重新发送" + time + "s";
						if (time == 0) {
							clearInterval(this.setTimeTwo);
							this.showTextTwo = true;
							this.secondTwo = "发送验证码";
						}
					}, 1000);
				}
			},
			codeCountdownThree(type) {
				this.showTextThree = false;
				let time = 60;
				if (time > 0) {
					var a = setInterval(() => {
						time = time - 1;
						this.secondThree = "重新发送" + time + "s";
						if (time == 0) {
							clearInterval(a);
							this.showTextThree = true;
							this.secondThree = "发送验证码";
						}
					}, 1000);
				}
			},
		},
	};
</script>
<style lang="less" scoped>
	.account {
		width: 100%;
		min-height: 100vh;
		background: #f6f7f8;

		.account-center {
			width: 100%;
			padding: 1.25rem 11.875rem;
			align-items: flex-start;

			.center-right {
				margin-left: 1.25rem;
				width: 100%;
				padding: 3.125rem 0 1.875rem 0;
				background: #fff;

				.right-padding {
					width: 100%;
					padding: 0 3.75rem;

					.padding-item {
						color: #9fa3b0;
						margin-bottom: 3.6875rem;

						>div:last-child {
							margin-top: 2.5rem;
						}

						.phone {
							margin-left: 1.25rem;
						}

						img {
							width: 13px;
							height: 12px;
							margin-right: 7px;
						}

						.img {
							width: 15px;
							height: 14px;
							margin-right: 0.375rem;
						}

						.add-wx {
							top: 14px;
							right: -20px;
							z-index: 100;
						}
					}

					.padding-wx-item {
						img {
							width: 15px;
							height: 14px;
						}

						.wx-text {
							margin-top: 10px !important;
							margin-bottom: 2.25rem;
						}

						.wx-logo {
							color: #9fa3b0;
							font-size: 0.8125rem;

							img {
								width: 1.5rem;
								height: 1.5rem;
								margin-right: 0.375rem;
							}
						}

						.email-text {
							color: #9fa3b0;
							font-size: 13px;
							margin-top: 36px;
						}

						.edit-wx {
							top: 14px;
							right: -10px;
							z-index: 100;
						}
					}
				}

				.right-padding-one {
					width: 100%;
					padding: 0 3.75rem;

					.password-phone {
						margin-left: 2.125rem;
						color: #9fa3b0;
					}

					.password-phone-one {
						margin-left: 3.8125rem;
					}

					.current-password {
						margin-top: 2.3125rem;
					}

					.el-text-input {
						.el-font-size {
							font-size: 14px;
						}

						margin-top: 35px;
						margin-bottom: 25px;

						.el-text-div-input {
							width: 300px;
							height: 34px;
							background: #ffffff;
							border: 1px solid #e3e7ed;
							margin-left: 21px;
							padding-left: 11px;

							input {
								width: 100%;
								height: 100%;
							}
						}
					}

					.el-confirm-input {
						margin-bottom: 56px;
						margin-left: 104px;

						.el-confirm-firrst-div {
							width: 300px;
							height: 34px;
							background: #ffffff;
							border: 1px solid #e3e7ed;
							padding-left: 11px;

							input {
								width: 100%;
								height: 100%;
							}
						}

						>div:last-child {
							margin-top: 1.5rem;
							width: 88px;
							height: 34px;
							background: #126bf9;
							border-radius: 4px;
						}

						.el-div-input {
							width: 300px;
							height: 38px;
							background: #ffffff;
							border: 1px solid #e3e7ed;
							padding-left: 18px;
							padding-right: 0.6875rem;
							margin-top: 24px;

							.el-code-input {
								border-left: 1px solid #e3e7ed;
								padding-left: 10px;
							}
						}
					}

					.text-zfb {
						margin-bottom: 2.0625rem;

						div {
							color: #9fa3b0;
							margin-left: 3.8125rem;
						}
					}

					.el-zfb-img {
						margin-left: 117px;
						width: 116px;
						height: 116px;
					}

					.el-certification-input {
						margin-left: 117px;

						.el-certification-div {
							width: 300px;
							height: 34px;
							background: #ffffff;
							border: 1px solid #e3e7ed;
							padding-left: 11px;
							margin-bottom: 20px;

							input {
								height: 100%;
							}
						}

						.el-button-submit {
							width: 88px;
							height: 34px;
							background: #126bf9;
							border-radius: 4px;
						}
					}
				}

				.el-noet-email {
					width: 100%;
					height: 225px;
					background: #f8f9fb;
					margin-bottom: 61px;
					padding: 25px 60px 20px 61px;

					.el-email-input {
						margin-top: 12px;
						margin-bottom: 25px;
						width: 316px;
						height: 38px;
					}

					.el-div-input {
						width: 316px;
						height: 38px;
						background: #ffffff;
						border: 1px solid #e3e7ed;
						padding-left: 18px;
						padding-right: 11px;
						margin-bottom: 20px;

						.el-code-num {
							border-left: 1px solid #e3e7ed;
							padding-left: 10px;
						}
					}

					.email-btn {
						div {
							width: 5.75rem;
							height: 2.25rem;
							margin-left: 1.1875rem;
						}

						div:first-child {
							background: #ffffff;
							border: 1px solid #e3e7ed;
						}
					}
				}
			}
		}

		.edit-phone {
			width: 100%;
			margin: 36px 0 20px 0;
			padding-left: 19px;

			/deep/.el-input {
				width: 80%;
			}

			/deep/.el-form-item__error {
				left: 79px;
			}

			.el_code-div {
				margin-left: 78px;

				/deep/.el-input {
					width: 70%;
				}
			}
		}

		.wx-phone {
			width: 100%;
			margin: 40px 0;
			padding-left: 10px;

			.wx-phone-text {
				div:last-child {
					margin-left: 30px;
				}
			}

			.wx-phone-input {
				margin-top: 40px;

				>div {
					padding-left: 14px;
				}

				.email-phone {
					padding-left: 40px;
				}

				.div-input {
					margin-left: 30px;
					width: 100%;
					height: 2.375rem;
					background: #ffffff;
					border: 1px solid #e3e7ed;
					padding-left: 1.125rem;
					padding-right: 0.6875rem;

					div {
						border-left: 1px solid #e3e7ed;
						padding-left: 0.625rem;
					}
				}
			}
		}
	}
</style>

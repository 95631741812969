<template>
  <div class="edit-img-wx position-relative">
    <img src="../../assets/forget/11.png" alt="" />
    <div class="position-absolute top-0 left-0">
      <div>{{title}}</div>
      <div class="edit-text-wx">
        {{centerText}}
      </div>
      <div class="row-end wx-edit-button">
          <!-- 必须阻止事件冒泡 -->
        <div class="text-blue flex-center" @click.stop="WxeditSubmit(1)">取消</div>
        <div class="flex-center bg-blue text-white" @click.stop="WxeditSubmit(2)">确认解除</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props:{
    title:{
      type:String
    },
    centerText:{
      type:String
    }
  },
  components: {},

  data() {
    return {};
  },

  created() {},

  mounted() {},

  methods: {
      WxeditSubmit(type){
          this.$emit('WxeditSubmit',type)
      }
  },
};
</script>
<style lang='less' scoped>
.edit-img-wx {
  width: 23.4375rem;
  height: 15.625rem;
  img {
    width: 100%;
    height: 100%;
  }
  > div {
    width: 100%;
    height: 100%;
    padding: 2.875rem 2.3125rem 2rem 2.25rem;
    color: #333333;
    font-size: 22px;
    .edit-text-wx {
      color: #9fa3b0;
      line-height: 29px;
      font-size: 13px;
      margin-top: 30px;
      margin-bottom: 20px;
    }
    .wx-edit-button {
      font-size: 13px;
      div:first-child {
        width: 82px;
        height: 34px;
        border: 1px solid #126bf9;
        margin-right: 20px;
      }
      div:last-child {
        width: 112px;
        height: 34px;
      }
    }
  }
}
</style>